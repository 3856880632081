import React, { useContext, useState, useEffect, lazy, Suspense, useCallback } from 'react';
import styled from 'styled-components'
import { Route, Redirect, useLocation } from 'react-router-dom'
import Request from '../service/Request'
import LoadPage from '../components/LoadPage';

import ClipLoader from "react-spinners/ClipLoader";
import { UserContext } from '../userContext'

import Sidebar from '../components/Sidebar'
import HeaderComponent from "../components/Header";
import Cookies from 'universal-cookie';

const LoginPage = lazy(() => import('../pages/Login'))
const ResetSenha = lazy(() => import('../pages/Login/ResetSenha'))
const HomePage = lazy(() => import('../pages/Home'))
const ErrorPage = lazy(() => import('../pages/Error'))

const TableUserPage = lazy(() => import('../pages/Users'))
const EditPage = lazy(() => import('../pages/Users/EditUser'))
const AddUserPage = lazy(() => import('../pages/Users/AddUser'))

const AlterPass = lazy(() => import('../pages/Users/AlterPass'))

const ClientsPage = lazy(() => import('../pages/Clients'))
const EditClient = lazy(() => import('../pages/Clients/EditClient'))
const AddClientPage = lazy(() => import('../pages/Clients/AddClient'))

// const BrandsPage = lazy(() => import('../pages/brands'))
// const AddBrandPage = lazy(() => import('../pages/brands/AddBrand'))
// const EditBrandPage = lazy(() => import('../pages/brands/EditBrand'))

const SKUPage = lazy(() => import('../pages/SKU/index'))
const AddSkuPage = lazy(() => import('../pages/SKU/AddSku/index'))
const EditSkuPage = lazy(() => import('../pages/SKU/EditSku'))

const ToxiFieldsPage = lazy(() => import('../pages/TaxoFields'))
const EditTaxoFieldsPage = lazy(() => import('../pages/TaxoFields/EditTaxoField'))
const AddTaxoFieldPage = lazy(() => import('../pages/TaxoFields/AddTaxoField'))

const AddNewFields = lazy(() => import('../pages/TaxoFields/newFields/addNewField'))
const UpdateNewFields = lazy(() => import('../pages/TaxoFields/newFields/updateNewField'))
const NewFields = lazy(() => import('../pages/TaxoFields/newFields/newFields'))

const ExtrasValues = lazy(() => import('../pages/TaxoFields/newFields/Values'))
const AddExtrasValues = lazy(() => import('../pages/TaxoFields/newFields/Values/addValue.js'))
const EditExtrasValues = lazy(() => import('../pages/TaxoFields/newFields/Values/upValue'))

const AddTemplates = lazy(() => import('../pages/TaxoFields/Templates/addTemplates'))
const TemplatesList = lazy(() => import('../pages/TaxoFields/Templates'))
const EditTemplates = lazy(() => import('../pages/TaxoFields/Templates/editTemplate'))

const AccountsPage = lazy(() => import('../pages/Accounts'))
const AddAccountPage = lazy(() => import('../pages/Accounts/AddAccount'))
const EditUserAdminAccountPage = lazy(() => import('../pages/Accounts/EditLoggedAccount'))
const EditAccountPage = lazy(() => import('../pages/Accounts/EditAccount'))

const GrupoAnuncio = lazy(() => import('../pages/GrupoAnuncio'))
const AdicionarGrAnuncio = lazy(() => import('../pages/GrupoAnuncio/add'))
const EditarGrAnuncio = lazy(() => import('../pages/GrupoAnuncio/edit'))
const BulkImport = lazy(() => import('../pages/BulkImport'))
const TestePage = lazy(() => import('../pages/Teste'))

function CustomRoute({ isPrivate, ...rest }) {
    const location = useLocation()
    const context = useContext(UserContext)
    const persistLogin = localStorage.getItem('persistedLogin')
    
    if (isPrivate && !context.user.logged && !persistLogin) {
        return <Redirect to='/login' />
    }

    return (
        <Route {...rest} />
    )
}

export default function RoutesComponent() {
    const user_id = localStorage.getItem('@taxonomy:user_id')
    const dataAcess = JSON.parse(localStorage.getItem('@taxnomy:access_data'))
    const [isError, setIsError] = useState(false)
    const context = useContext(UserContext)
    const Api = new Request()
    const cookies = new Cookies();

    useEffect(() => {
        setIsError(false)
    }, [])

    const getUserLogged = useCallback(async () => {
        context.setLoadingGlobal(true)
        const request = await Api.GET('/users/get')
        
        if (user_id) {
            let acc = request?.account

            if(cookies.get('@taxonomy:account')) {
                acc = await Api.GET(`/accounts/show/${cookies.get('@taxonomy:account')}`)
            }


            context.validateUser(
                dataAcess?.access_token,
                dataAcess?.token_type,
                '',
                true,
                request?.user,
                acc,
            )
            
            if(request?.status === 498) {
                context.validateUser(
                    '',
                    '',
                    '',
                  false,
                  {},
                  {},
                )
                localStorage.clear()
            }

        } else {
            localStorage.clear()
        }
        
        setTimeout(() => {
            context.setLoadingGlobal(false)
        }, [])
    }, [Api])
    
    useEffect(() => {
        getUserLogged()  
      }, [])

    if (context.loadingGlobal) {
        return <LoadPage />
    }

    return (
        <>
            {context?.user.logged ?
                <>
                    <HeaderComponent error={isError} />
                    <div className='container-fluid'>

                        <div className='row'>
                            <Sidebar />
                            <Suspense fallback={<ClipLoader />}>

                                <CustomRoute exact path='/import'>
                                    <BulkImport />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/granuncio'>
                                    <GrupoAnuncio />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/granuncio/add'>
                                    <AdicionarGrAnuncio />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/granuncio/edit/:id'>
                                    <EditarGrAnuncio />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/'>
                                    <SKUPage />
                                </CustomRoute>
                                
                                <CustomRoute isPrivate exact path='/alter-pass'>
                                    <AlterPass />
                                </CustomRoute>

                                {/* USUARIO */}
                                <CustomRoute isPrivate exact path='/users'>
                                    <TableUserPage />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/users/update/:id'>
                                    <EditPage />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/add/users'>
                                    <AddUserPage />
                                </CustomRoute>

                                {/* CLIENTES */}
                                <CustomRoute isPrivate exact path='/clients'>
                                    <ClientsPage />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/add/clients'>
                                    <AddClientPage />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/clients/update/:id'>
                                    <EditClient />
                                </CustomRoute>

                                {/* SKUS */}
                                <CustomRoute isPrivate exact path='/SKU'>
                                    <AddSkuPage />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/SKU/update/:id'>
                                    <EditSkuPage />
                                </CustomRoute>

                                {/* CAMPOS DA TAXONOMIA */}
                                <CustomRoute isPrivate exact path='/clients/toxonomy/:field'>
                                    <ToxiFieldsPage />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/clients/toxonomy/:field/update/:id'>
                                    <EditTaxoFieldsPage />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/clients/toxonomy/:field/add'>
                                    <AddTaxoFieldPage />
                                </CustomRoute>

                                {/* NOVOS CAMPOS */}
                                <CustomRoute isPrivate exact path='/toxonomy/add'>
                                    <AddNewFields />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/toxonomy/update/:id'>
                                    <UpdateNewFields />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/toxonomy/list'>
                                    <NewFields />
                                </CustomRoute>

                                {/* VALORES ADICIONAIS */}
                                <CustomRoute isPrivate exact path='/toxonomy/extras/:field'>
                                    <ExtrasValues />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/toxonomy/extras/:field/add'>
                                    <AddExtrasValues />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/toxonomy/extras/:field/update/:id'>
                                    <EditExtrasValues />
                                </CustomRoute>

                                {/* TEMPLATES */}
                                <CustomRoute isPrivate exact path='/toxonomy/templates'>
                                    <TemplatesList />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/toxonomy/templates/update/:id'>
                                    <EditTemplates />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/toxonomy/templates/add'>
                                    <AddTemplates />
                                </CustomRoute>

                                {/* ACCOUNTS */}
                                <CustomRoute isPrivate exact path='/accounts'>
                                    <AccountsPage />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/add/account'>
                                    <AddAccountPage />
                                </CustomRoute>

                                <CustomRoute isPrivate exact path='/accounts/update/:id'>
                                    <EditAccountPage />
                                </CustomRoute>

                                {/* PAGINA DE ERRO */}
                                <CustomRoute exact path="/error">
                                    <ErrorPage />
                                </CustomRoute>

                                {/* TESTE */}
                                {/* <CustomRoute exact path='/teste'>
                                    <TestePage />
                                </CustomRoute> */}
                            </Suspense>
                        </div>
                    </div>
                </>
            :
                <>
                    <Suspense fallback={<LoadPage />}>

                        <Route exact path="/reset">
                            <ResetSenha />
                        </Route>

                        <Route exact path="">
                            <LoginPage />
                        </Route>

                    </Suspense>
                </>
            }

        </>
    )
}