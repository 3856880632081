import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import Axios from 'axios'
import { FaHome, FaFile, FaIdBadge, FaBookmark, FaListAlt, FaFlag, FaIdCard, FaLayerGroup, FaFileExcel } from 'react-icons/fa'
import styled from 'styled-components'
import { Link, Redirect, useHistory } from 'react-router-dom'
import logOut from '../../utils/functions/logout'
import Request from '../../service/Request'

import ManualC from '../../utils/Manuais/Manual Comum.pdf'
import ManualA from '../../utils/Manuais/Manual Adm.pdf'
import ManualM from '../../utils/Manuais/Manual Master.pdf'

import { UserContext } from '../../userContext'

import './style.css';

const ItemSidebarStyled = styled.span`
    cursor: pointer;

    & span, & svg {
      color: rgb(${props => props.colors.tertiary});
    }

    .nav-link:hover * {
      color: rgb(${props => props.colors.secondary});
    }
`


function SidebarComponent() {
  const history = useHistory()
  const Api = new Request()
  const dataObj = JSON.parse(localStorage.getItem('access_data'))
  const user_id = localStorage.getItem('@taxonomy:user_id')
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const ctx = useContext(UserContext)
  const [data, setData] = useState()
  const [context, setContext] = useState()
  const [accounts, setAccounts] = useState()

  const [extras, setExtras] = useState([])

  const [onMobile, setOnMobile] = useState(false)

  const getExtraFields = useCallback(() => {
    if(ctx.user.user?.is_master === 0) {
      if(ctx.user.user?.is_admin === 1) {
        Axios({
          method: 'POST',
          url: `${process.env.REACT_APP_USER_URL}/extra_fields/list`,
          data: {
            account_id: ctx.user.account?.id
          },
          headers: {
            'Authorization': `${ctx?.user.token_type} ${ctx?.user.access_token}`
          }
        })
        .then(res => { 
          setExtras(res.data)
          setIsLoading(false)
       })
      }
      
    setIsLoading(false)
    } else {
      Axios({
        method: 'POST',
        url: `${process.env.REACT_APP_USER_URL}/extra_fields/list`,
        data: {
          account_id: ctx.user.account?.id
        },
        headers: {
          'Authorization': `${ctx?.user.token_type} ${ctx?.user.access_token}`
        }
      })
      .then(res => { 
        setExtras(res.data)
        setIsLoading(false)
     })
    }
  }, [ctx])

  useEffect(() => {
    getExtraFields()
  }, [ctx, getExtraFields])

  // setTimeout(() => {
  //   setIsLoading(false)
  // }, 1000)

  function redirectToTaxonomyPage(url) {
    return history.push(url)
  }

  function getManual() {
    if(ctx.user.user.is_master) {
      return ManualM
    }

    if(ctx.user.user.is_admin) {
      return ManualA
    }

    return ManualC
  }

  const menuFieldsArr = useMemo(() => [
    { name: "Unidade de Negócio", redirect: "business_unity", show: ctx?.user.account?.show_business_unities },
    { name: "Marcas", redirect: "brands", show: ctx?.user.account?.show_brands },
    { name: "Tipo de Mensagem", redirect: "message_types", show: ctx?.user.account?.show_message_types },
    { name: "Categorias", redirect: "categories", show: ctx?.user.account?.show_categories },
    { name: "Destino", redirect: "targets", show: ctx?.user.account?.show_targets },
    { name: "Campanha", redirect: "campaign", show: ctx?.user.account?.show_campaigns },
    { name: "Sub-Campanha", redirect: "sub_campaigns", show: ctx?.user.account?.show_sub_campaigns },
    { name: "Criativo", redirect: "creatives", show: ctx?.user.account?.show_creatives },
    { name: "Veículo", redirect: "media_targets", show: ctx?.user.account?.show_media_targets },
    { name: "Posicionamento", redirect: "positionings", show: ctx?.user.account?.show_positionings },
    { name: "Formato", redirect: "formats", show: ctx?.user.account?.show_formats },
    { name: "Objetivo", redirect: "purposes", show: ctx?.user.account?.show_purposes },
    { name: "KPIs", redirect: "kpis", show: ctx?.user.account?.show_kpis },
    { name: "Pilar", redirect: "cornerstones", show: ctx?.user.account?.show_cornerstones },
    { name: "Plataformas", redirect: "platforms", show: ctx?.user.account?.show_platforms },
    { name: "Tipo 1", redirect: "type_ones", show: ctx?.user.account?.show_type_ones },
    { name: "Tipo 2", redirect: "type_twos", show: ctx?.user.account?.show_type_twos },
  ], [ctx])

  // useEffect(() => console.log(ctx), [ctx])

  const MenuItem = ({ label ='home', path = '/', icon = <FaHome className='mx-3' /> }) => {
    return (
      <ItemSidebarStyled className="nav-item" colors={ctx.user.theme}>
        <Link className="nav-link d-flex align-items-center" to={path}>
            {icon}
            <span className={window.screen.availWidth < 992 ? `open-lg-menu collapsed` : ''} type={window.screen.availWidth < 992 ? "button" : ''} data-toggle={window.screen.availWidth < 992 ? "collapse" : ''} data-target={window.screen.availWidth < 992 ? "#sidebarMenu" : ''} aria-controls={window.screen.availWidth < 992 ? "sidebarMenu" : ''} aria-expanded={window.screen.availWidth < 992 ? "false" : ''} aria-label={window.screen.availWidth < 992 ? "Toggle navigation" : ''} >
              {label}
            </span>
        </Link>
      </ItemSidebarStyled>
    )
  }

  if (isLoading || ctx?.user.user === undefined || ctx?.user.account === undefined) {
    return <h1>...</h1>
  }

  return (
    <nav id="sidebarMenu" className="col-md-12 col-lg-2 d-lg-block bg-light sidebar collapse">
      <div className="sidebar-sticky pt-5 pt-sm-3">
        <ul className="nav flex-column">

          <li className='nav-link d-flex justify-content-center flex-column'>
            <div className='row w-md-100 justify-content-between ml-2'>
              <div>
                {ctx?.user.account && Object.values(ctx?.user?.account).length > 0
                  // eslint-disable-next-line jsx-a11y/alt-text
                  ? <img
                    style={{ width: '120px', height: '40px' }}
                    src={`${ctx?.user.account?.account_logo}`}
                    className='img-fluid ml-3' />
                  : <h3 className='ml-3 text-capitalize'>{ctx?.user.account ? ctx?.user.account?.name : '...'}</h3>}
                  <br />
                <span className='lead ml-3 mt-2'>{ctx?.user.user?.name}</span>
              </div>
            </div>
          </li>

          <MenuItem label="Home" path="/" icon={<FaHome className='mx-3' />} />
          <MenuItem label="Cadastrar SKU" path="/SKU" icon={<FaFile className='mx-3' />} />
          <MenuItem label="Grupos de anuncio" path="/granuncio" icon={<FaLayerGroup className='mx-3' />} />
          <MenuItem label="Importar Lote" path="/import" icon={<FaFileExcel className='mx-3' />} />

          <ItemSidebarStyled className="nav-item" colors={ctx.user.theme}>
            <a className="nav-link d-flex align-items-center" href={getManual()} download='Manual_Taxonomias'>
              <FaFlag className='mx-3' />
              <span className={window.screen.availWidth < 992 ? `open-lg-menu collapsed` : ''} type={window.screen.availWidth < 992 ? "button" : ''} data-toggle={window.screen.availWidth < 992 ? "collapse" : ''} data-target={window.screen.availWidth < 992 ? "#sidebarMenu" : ''} aria-controls={window.screen.availWidth < 992 ? "sidebarMenu" : ''} aria-expanded={window.screen.availWidth < 992 ? "false" : ''} aria-label={window.screen.availWidth < 992 ? "Toggle navigation" : ''}>
                Manual
              </span>
            </a>
          </ItemSidebarStyled>

          {ctx.user.user?.is_admin === 1 || ctx.user.user?.is_master === 1 ?
            <>
              <MenuItem label="Usuarios" path="/users" icon={<FaIdCard className='mx-3' />} />

              <MenuItem label="Clientes" path="/clients" icon={<FaIdBadge className='mx-3' />} />
            </>
            : null
          }

          {ctx.user.user?.is_master === 1 ?
            <div>
              <MenuItem label="Contas" path="/accounts" icon={<FaBookmark className='mx-3' />} />
            </div>
            : null
          }

          {
            ctx.user.user?.is_admin === 1 || ctx.user.user?.is_master === 1 ?
              <div>
                {/* <ItemSidebarStyled className="nav-item" colors={ctx.user.theme}>
                      <Link className="nav-link d-flex align-items-center" to='/account'>
                        <FaIdCard className='mx-3' />
                        <span>Conta</span>
                      </Link>
                    </ItemSidebarStyled> */}

                <div className="accordion" id="listTaxonomyAccordion">

                  <ItemSidebarStyled className="nav-item" colors={ctx.user.theme}>
                    <span data-toggle='collapse'
                      data-target='#listTaxonomy'
                      className="nav-link d-flex align-items-center">
                      <FaListAlt className='mx-3' />
                      <span>
                        Campos Auxiliares
                      </span>
                    </span>
                  </ItemSidebarStyled>

                  <div id="listTaxonomy" className="collapse" data-parent="#listTaxonomyAccordion">
                    <ul className='list-group'>

                      {/*<ItemSidebarStyled className="nav-item" colors={ctx.user.theme}>
                        <Link className="nav-link d-flex align-items-center" to='/clients/toxonomy/brands'>
                          <span>Marcas</span>
                        </Link>
                      </ItemSidebarStyled>*/}

                      {ctx?.user.user.is_admin === 1 || ctx?.user.user.is_master === 1  ?
                        <li className='list-group-item'>
                          <ItemSidebarStyled className="nav-item" colors={ctx.user.theme}>
                            <span
                              onClick={() => redirectToTaxonomyPage(`/toxonomy/list`)}
                              className="nav-link d-flex align-items-center" to="/">
                              <span className={window.screen.availWidth < 992 ? `open-lg-menu collapsed` : ''} type={window.screen.availWidth < 992 ? "button" : ''} data-toggle={window.screen.availWidth < 992 ? "collapse" : ''} data-target={window.screen.availWidth < 992 ? "#sidebarMenu" : ''} aria-controls={window.screen.availWidth < 992 ? "sidebarMenu" : ''} aria-expanded={window.screen.availWidth < 992 ? "false" : ''} aria-label={window.screen.availWidth < 992 ? "Toggle navigation" : ''}>
                                Campos Adicionais
                                      </span>
                            </span>
                          </ItemSidebarStyled>
                        </li>
                        : null
                      }

                      {ctx.user?.user?.is_admin === 1 || ctx.user?.user?.is_master === 1 ?
                        
                        extras?.map(item => {
                          if(item.field.active === 0) {
                            return null
                          }
                          
                          return (
                          <li key={item.field.id} className='list-group-item'>
                            <ItemSidebarStyled className="nav-item" colors={ctx.user.theme}>
                              <span
                                onClick={() => redirectToTaxonomyPage(`/toxonomy/extras/${item.field.id}`)}
                                className="nav-link d-flex align-items-center">
                                <span style={{ textTransform: 'capitalize' }} className={window.screen.availWidth < 992 ? `open-lg-menu collapsed` : ''} type={window.screen.availWidth < 992 ? "button" : ''} data-toggle={window.screen.availWidth < 992 ? "collapse" : ''} data-target={window.screen.availWidth < 992 ? "#sidebarMenu" : ''} aria-controls={window.screen.availWidth < 992 ? "sidebarMenu" : ''} aria-expanded={window.screen.availWidth < 992 ? "false" : ''} aria-label={window.screen.availWidth < 992 ? "Toggle navigation" : ''}>
                                  {item.field.name}
                                </span>
                              </span>
                            </ItemSidebarStyled>
                          </li>
                          )
                        })

                        : null
                      }

                      {menuFieldsArr.map((x, key) => {

                        if(x.show) {
                          return (
                            <li key={key} className='list-group-item'>
                              <ItemSidebarStyled className="nav-item" colors={ctx.user.theme}>
                                <span
                                  href="#"
                                  onClick={() => redirectToTaxonomyPage(`/clients/toxonomy/${x.redirect}`)}
                                  className="nav-link d-flex align-items-center">
                                  <span className={window.screen.availWidth < 992 ? `open-lg-menu collapsed` : ''} type={window.screen.availWidth < 992 ? "button" : ''} data-toggle={window.screen.availWidth < 992 ? "collapse" : ''} data-target={window.screen.availWidth < 992 ? "#sidebarMenu" : ''} aria-controls={window.screen.availWidth < 992 ? "sidebarMenu" : ''} aria-expanded={window.screen.availWidth < 992 ? "false" : ''} aria-label={window.screen.availWidth < 992 ? "Toggle navigation" : ''}>
                                    {x.name}
                                          </span>
                                </span>
                              </ItemSidebarStyled>
                            </li>
                          )
                        }

                      })}

                        <li className='list-group-item'>
                          <ItemSidebarStyled className="nav-item" colors={ctx.user.theme}>
                            <span
                              onClick={() => redirectToTaxonomyPage(`/toxonomy/templates`)}
                              className="nav-link d-flex align-items-center" to="/">
                              <span className={window.screen.availWidth < 992 ? `open-lg-menu collapsed` : ''} type={window.screen.availWidth < 992 ? "button" : ''} data-toggle={window.screen.availWidth < 992 ? "collapse" : ''} data-target={window.screen.availWidth < 992 ? "#sidebarMenu" : ''} aria-controls={window.screen.availWidth < 992 ? "sidebarMenu" : ''} aria-expanded={window.screen.availWidth < 992 ? "false" : ''} aria-label={window.screen.availWidth < 992 ? "Toggle navigation" : ''}>
                                Templates
                              </span>
                            </span>
                          </ItemSidebarStyled>
                        </li>

                    </ul>
                  </div>
                </div>
              </div>
              : null}

        </ul>
      </div>
    </nav>
  );
}

export default SidebarComponent;