import React from "react";
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";

import UserProvider from './userContext'
import RoutesComponent from './routes/index'

export default function App() {

  return (  
    <Router>
      <Switch>
        <UserProvider>
          <RoutesComponent />
        </UserProvider>
      </Switch>
    </Router >
  );
}
