import Axios from 'axios'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function logOut(ctx, history, redirect, setRedirect) {
  Axios({
    method: 'POST',
    url: `${process.env.REACT_APP_LOGOUT_URL}`,
    data: {
      token: ctx.user.access_token
    }
  })
  .then(res => {
      ctx.validateUser('', '', '', false, {}, {}, {} );
      localStorage.clear()
      cookies.remove('@taxonomy:account')
      history.push('/')
      return
  })
  .catch(err => console.log(err))
  .finally(() => console.log('aaaa'))
}