import React, { useContext } from 'react';
import styled from 'styled-components'
import { UserContext  } from '../../userContext'

const ButtonStyled = styled.button`
    background: rgb(${props => props.type === 'cancel' ? props.colors.secondary : props.type === 'danger' ? props.colors.tertiary : props.colors.primary });
    border-color: rgb(${props => props.type === 'cancel' ? props.colors.secondary : props.type === 'danger' ? props.colors.tertiary : props.colors.primary });
    transition: all 0.2s ease;
    color: white;
    padding: 5px 5px;

    &:hover, &:active, &:focus {
      color: white;
      box-shadow: 0 0 0 0.2rem rgba(${props => props.type === 'cancel' ? props.colors.secondary : props.type === 'danger' ? props.colors.tertiary : props.colors.primary }, 0.40);
    }

    a, span {
      padding: 8px 15px;
      color: white;
      text-decoration: none;
    }
`

function ButtonComponent({ action, children, colors, type, rest }) {
  const ctx = useContext(UserContext)

  return (
      <ButtonStyled 
          { ...rest }
          type={type}
          colors={ctx?.user.theme}
          className='btn' 
          onClick={ () => action ? action() : null } 
          >
            { children }
      </ButtonStyled>
  );
}

export default ButtonComponent;