import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useHistory, Redirect, Link } from 'react-router-dom'
import styled from 'styled-components'
import { FaUser, FaSignOutAlt, FaBars } from 'react-icons/fa'
import logout from '../../utils/functions/logout'
import Axios from 'axios'
import Request from '../../service/Request'
import Cookies from 'universal-cookie';

import ButtonComponent from '../Button'

import { UserContext } from '../../userContext'

import './style.css';

const HeaderStyled = styled.header`
  background-color: rgb(${props => props.colors.tertiary});
`

function HeaderComponent({ error }) {
  const Api = new Request()
  const cookies = new Cookies();
  const context = useContext(UserContext)
  const history = useHistory()
  const [redirect, setRedirect] = useState(false)
  const [accounts, setAccounts] = useState()
  const [selectedAcc, setSelectedAcc] = useState(cookies.get('@taxonomy:account')?? context.user.account?.id)
  const [reflesh, setReflesh] = useState(0)

  useEffect(() => {
    setAccounts(context.accounts)
  }, [context])

  const selectAccount = useCallback( async (id) => {
    if (context?.user.user?.is_master === 0) {
      return
    } else {
        const result = await Api.GET(`/accounts/show/${id}`) 
        const res = await result
          context.setLoadingGlobal(true)
          //context.getAccountData(res.data.id)

          let theme = {
            primary: res.data?.color_primary,
            secondary: res.data?.color_secondary,
            tertiary: res.data?.color_tertiary,
          }


          context.validateUser(
            context.user?.access_token,
            context.user?.token_type,
            context.user?.expires_in,
            true,
            context.user?.user,
            res,
            theme)
          setTimeout(() => {
            context.setLoadingGlobal(false)
          }, 500)

        //.catch(() => {
        //    context.setUser({})
        //})
    }
  }, [])

  const LogOut = useCallback(() => {
    Axios({
      method: 'POST',
      url: `${process.env.REACT_APP_USER_URL}/logout`,
      data: {
        token: context.user.access_token
      }
    })
    .then(res => {
        context.validateUser('', '', '', false, {}, {}, {} );
        cookies.remove('@taxonomy:account')
        localStorage.clear()
        history.push('/')
    })
    .catch(err => console.log(err))
    .finally(() => history.push('/'))
  }, [])

  return (
    <>
      <HeaderStyled colors={context?.user.theme} className="w-100 navbar navbar-dark sticky-top shadow" style={{ margin: '0px' }}>

        {redirect ? <Redirect to='/' /> : null}
        <div className="w-100 row justify-content-center flex-sm-nowrap" style={{ margin: '0px' }}>

          <div className='col-sm-6 col-12 mb-2 mb-sm-0 mr-2 d-flex justify-content-sm-start justify-content-between align-items-center'>
            <ButtonComponent colors={context?.user.theme} action={() => { }}>
              <a className="align-middle" data-target="#exitModal" data-toggle="modal" href="http://localhost:3000/#">
                <FaSignOutAlt className='mx-1' />
                      Logout
              </a>
            </ButtonComponent>
            {context.user.user?.is_master && accounts?.length > 0 ?

              <select value={selectedAcc} onChange={e => { 
                setSelectedAcc(e.target.value)
                cookies.set('@taxonomy:account', e.target.value);
                selectAccount(e.target.value) 
                            
              }} className='form-control d-block mx-2 rounded' style={{ width: 'auto', padding: 'initial'}}>

                {accounts?.map(account => (
                  <option value={account?.id} key={account?.id} >{account?.name}</option>
                ))}
              </select>

              : null
            }
          </div>

          <div className='mr-2 col-sm-6 col-12 justify-content-between justify-content-sm-end d-flex'>
            <ButtonComponent colors={context?.user.theme} action={() => { }}>
              <Link to={`/alter-pass`}>
                <FaUser className='mx-1 d-none d-md-inline' />
                <span className='altPass'>Alterar Senha</span>
              </Link>
            </ButtonComponent>

            <span className="navbar-toggler open-menu mx-3 d-lg-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
              <FaBars />
            </span>
          </div>

        </div>

      </HeaderStyled>

      <div className="modal fade" id="exitModal" tabIndex="-1" aria-labelledby="exitModal" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exitModalLabel">Tem certeza que deseja sair?</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-footer">
              <ButtonComponent text='Não' type='cancel' colors={context?.user.theme} action={() => { }} >
                <a data-dismiss="modal" >Não</a>
              </ButtonComponent>

              <ButtonComponent action={() => LogOut()} colors={context?.user.theme} text='Sim'>
                <a data-dismiss="modal">Sim</a>
              </ButtonComponent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderComponent;