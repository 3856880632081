import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";

const moveInLeft = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`

const SLoadPage = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
	background: rgb(255, 255, 255);
	background: rgb(52, 58, 64);
    z-index: 10000;
    right: 0px;
    animation: ${moveInLeft} 1s ease-in-out .3s both reverse;
`

function LoadPage() {
    const [ timer, setTimer ] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setTimer(false);
        }, 3000);
    }, []);

    if(timer) {
        return (
            <SLoadPage>
                <ClipLoader color='#fff' />
            </SLoadPage>
        )

    }

    return (
        <h1>ksdfnhsbgbfgm</h1>
    )
}

export default LoadPage;