import React, { useState, createContext, useEffect, useCallback } from 'react'
import Request from './service/Request'
import Cookies from 'universal-cookie';

export const UserContext = createContext({})

const UserProvider = (({ children }) => {
    const [loadingGlobal, setLoadingGlobal] = useState(false)
    const Api = new Request()
    const cookies = new Cookies();
    const [AccountUsers, setAccountUsers] = useState()
    const [accounts, setAccounts] = useState()
    const [clients, setClients] = useState()
    const data_obj = JSON.parse(localStorage.getItem('access_data'))
    const uri = `/users/get`
    const data = {}

    const getAccountData =useCallback(async (id) => {
        const request = await Api.GET('/accounts/list/0')
        const result = await request
        setAccounts(result)
    }, [Api])
    
    const getUsersOfAccount =useCallback(async (id) => {
        const request = await Api.GET(`/users/list/${id}`)
        const result = await request
        setAccountUsers(result)
    }, [Api])
    
    const INITIAL_THEME = {
        primary: '130, 87, 230',
        secondary: '4, 211, 97',
        tertiary: '52, 58, 64',
    }
    
    
    const [user, setUser] = useState({
        access_token: localStorage.getItem('access_data') ? data_obj.access_token : '',
        token_type: localStorage.getItem('access_data') ? data_obj.token_type : '',
        expire_in: null,
        logged: localStorage.getItem('persistedLogin') ? true : false,
        user: {},
        account: {},
        AccountUsers: [],
        theme: INITIAL_THEME
    })
    
    useEffect(() => {
        if(user.user?.id) {
            getAccountData(user.user?.id)
            getUsersOfAccount(user.account?.id)
        }
    }, [user])
    

    const validateUser = (access_token = '', token_type = '', expire_in = false, logged = '', user = data?.user, account = data?.account, theme) => {
        setUser({
            access_token,
            token_type,
            expire_in,
            logged,
            user,
            account,
            theme: {
                primary: account?.color_primary ?? INITIAL_THEME.primary,
                secondary: account?.color_secondary ?? INITIAL_THEME.secondary,
                tertiary: account?.color_tertiary ?? INITIAL_THEME.tertiary,
            }
        })
    }

    return (
        <UserContext.Provider 
            value={{ user,
                    validateUser, 
                    loadingGlobal, 
                    setLoadingGlobal, 
                    AccountUsers, 
                    setAccountUsers,
                    getAccountData,
                    setUser,
                    accounts,
                    clients,
                    setClients
                    }}>
            { children}
        </UserContext.Provider>
    )
})

export default UserProvider