import { UserContext } from '../userContext'
import Axios from 'axios'

import api from './api'

export default class Request {

    GET = async (url) => {
        const data_obj = JSON.parse(localStorage.getItem('@taxnomy:access_data'))
        const { REACT_APP_USER_URL, REACT_APP_API_ENERGISA_JL } = process.env
        
        try{
            const response = await  Axios({
                method: 'GET',
                url: `${REACT_APP_USER_URL}${url}`,
                headers: {
                    'Authorization': `${data_obj.token_type} ${data_obj.access_token}`
                }
            })
        
            const data = await  response.data
        
            return data
        } catch(err) {
            return
        }
    }
}